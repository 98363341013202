import React from "react"
import {NewsLayout} from "sections/News/"
import useNewsData from "shared/Hooks/News/useNewsData"
import {TextBlock} from "src/sections/News/NewsLayout/TextBlock";
import {NEWS_36} from "types/News"
import {Images} from "../../sections/News/NewsLayout/Images";

const Article = () => {
    const data = useNewsData(NEWS_36);

    return (
        <NewsLayout data={data}>
            <Images data={data.images.first}/>
            <TextBlock data={data.texts.first}/>
            <TextBlock data={data.texts.second}/>
            <TextBlock data={data.texts.third}/>
            <TextBlock data={data.texts.fourth}/>
        </NewsLayout>
    )
}

export default Article;
